/* add css module styles here (optional) */

._1Lxpd {
  position: relative;
  width: 50%;
  overflow: hidden !important;
}

._2hdkJ {
  display: block;
  width: 100%;
  height: auto;
}

._3qw0K {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  color: white;
}

._2lGdA {
  transform: translateX(-400px);
}

._2ehri {
  transform: translateX(400px);
}

._vpxAd {
  transform: translateY(-500px);
}

._2BlAx {
  transform: translateY(500px);
}

._1Lxpd:hover ._3qw0K {
  opacity: 1;
  transform: translateX(0px);
  transform: translateY(0px);
}

._5bXm4 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
